import { UserStorage } from "#js/components/storages"
import { gettext } from "#js/components/i18n"
import { capFirst, isBreakpoint } from "#js/components/utils"
import { offset } from "@floating-ui/dom"
import Shepherd from "shepherd.js"
import { fetchJSON } from "#js/components/http"
import { fetchCompanyConfig, fetchUserConfig } from "#js/components/config"

const userStorage = new UserStorage()

export class WelcomeTour extends Shepherd.Tour {
  tourKey = "tour-welcome"
  states = Object.freeze({
    STARTED: "started",
    CANCELED: "canceled",
    FINISHED: "finished",
  })

  constructor(options) {
    super({
      useModalOverlay: true,
      exitOnEsc: false,
      keyboardNavigation: false,
      defaultStepOptions: {
        scrollTo: true,
        classes: "welcome-tour-shepherd-element",
        cancelIcon: {
          enabled: true,
        },
      },
      ...options,
    })
  }

  async getState() {
    return await userStorage.getItem(this.tourKey)
  }

  async setState(state) {
    await userStorage.setItem(this.tourKey, state)
  }

  async continue() {
    const config = await fetchCompanyConfig()
    if (
      await this.getState() === this.states.STARTED &&
      !config.product_tour.is_eligible_for_product_tour
    ) {
      await this.start()
    }
  }

  async start() {
    await this.setState(this.states.STARTED)
    await super.start()
  }

  async cancel() {
    await this.setState(this.states.CANCELED)
    await super.cancel()
  }

  /* istanbul ignore next */
  backInHistoryButton = {
    text: gettext("back"),
    action: () => {
      globalThis.history.back()
    },
    classes: "back-tour",
  }

  /* istanbul ignore next */
  backInTourButton() {
    return {
      text: gettext("back"),
      action: this.back,
      classes: "back-tour",
    }
  }

  /* istanbul ignore next */
  nextButton() {
    return {
      text: gettext("next"),
      action: this.next,
    }
  }

  /* istanbul ignore next */
  doneButton() {
    return {
      text: gettext("done"),
      action: async () => {
        await this.setState(this.states.FINISHED)
        this.complete()
      },
    }
  }

  /* istanbul ignore next */
  async startHomeTour() {
    const config = await fetchJSON(globalThis.company.configUrl)
    if (
      await userStorage.getItem(this.tourKey) == null &&
      !config.product_tour.is_eligible_for_product_tour &&
      isBreakpoint("desktop")
    ) {
      console.debug("Starting platform tour")
      document.getElementById("welcome-tour-welcome").showModal()
    }
  }

  /* istanbul ignore next */
  startOfferDetailTour() {
    this.addStep({
      title: gettext("Dive into the details!"),
      text: gettext(
        "Here you’ll find everything you need to know about the offer, including a detailed description and any equipment or tools you might need.",
      ),
      canClickTarget: false,
      attachTo: {
        element: '[data-tour="description-section"]',
        on: "bottom-start",
      },
      scrollTo: true,
      modalOverlayOpeningPadding: 12,
      arrow: false,
      buttons: [this.backInHistoryButton, this.nextButton()],
    })

    this.addStep({
      title: capFirst(gettext("quick facts at your fingertips")),
      text: gettext(
        "This sidebar gives you a handy overview of the offer, with all the important details in one place. It's a great place to check for the essentials!",
      ),
      canClickTarget: false,
      attachTo: {
        element: '[data-tour="info-section"]',
        on: "left-end",
      },
      floatingUIOptions: {
        middleware: [offset(50)],
      },
      scrollTo: false,
      modalOverlayOpeningPadding: 12,
      arrow: false,
      buttons: [this.backInTourButton(), this.nextButton()],
    })

    this.addStep({
      title: gettext("Choose what works for you!"),
      text: gettext(
        "Now it’s time to select the booking option that fits your needs. Simply pick the one that suits you best!",
      ),
      canClickTarget: true,
      attachTo: {
        element: '[data-tour="booking-section"]',
        on: "top-end",
      },
      scrollTo: false,
      modalOverlayOpeningPadding: 12,
      floatingUIOptions: {
        middleware: [offset(50)],
      },
      arrow: false,
    })

    this.continue()
  }

  /* istanbul ignore next */
  startOfferBookedTour() {
    this.addStep({
      title: gettext("Booking confirmed — You're all set!"),
      text: gettext(
        "Congrats! Your booking is confirmed, and we can’t wait for you to experience it. Enjoy, and feel free to reach out if you need anything else along the way.",
      ),
      cancelIcon: {
        enabled: false,
      },
      buttons: [this.doneButton()],
    })

    this.continue()
  }

  /* istanbul ignore next */
  startTalkTour() {
    this.addStep({
      title: capFirst(gettext("discover this live cast series")),
      text: gettext(
        "Each series is unique and contains a variety of engaging talks, with new ones added regularly. You’ll find everything you need to know about the current series here. Let’s get you started with this one!",
      ),
      attachTo: {
        element: '[data-tour="series-info"]',
        on: "bottom",
      },
      scrollTo: false,
      arrow: false,
      modalOverlayOpeningPadding: 12,
      canClickTarget: false,
      buttons: [this.backInHistoryButton, this.nextButton()],
    })

    this.addStep({
      title: gettext("What’s this live cast about?"),
      text: gettext("Learn more about the upcoming live cast episode here."),
      attachTo: {
        element: '[data-tour="talk"]',
        on: "bottom",
      },
      arrow: false,
      scrollTo: true,
      canClickTarget: false,
      modalOverlayOpeningPadding: 12,
      buttons: [this.backInTourButton(), this.nextButton()],
    })

    this.addStep({
      title: gettext("Reserve a seat!"),
      text: gettext('Click "Save a seat" to reserve a spot!'),
      attachTo: {
        element: '[data-tour="save-seat-button"]',
        on: "bottom-end",
      },
      arrow: false,
      scrollTo: false,
      modalOverlayOpeningPadding: 12,
      canClickTarget: true,
      advanceOn: {
        selector: "#save-seat-button",
        event: "click",
      },
    })

    this.addStep({
      title: gettext("Seat confirmed — You're all set!"),
      text: gettext(
        "Congrats! Your seat is saved, and we can’t wait for you to experience the livecast. Enjoy, and feel free to reach out if you need anything else along the way.",
      ),
      cancelIcon: {
        enabled: false,
      },
      buttons: [this.doneButton()],
    })

    this.continue()
  }

  /* istanbul ignore next */
  startTalkRecordingTour() {
    this.addStep({
      title: gettext("Recording booked!"),
      text: gettext(
        "Congrats! Enjoy this recording, and feel free to reach out if you need anything else along the way.",
      ),
      cancelIcon: {
        enabled: false,
      },
      buttons: [this.backInHistoryButton, this.doneButton()],
    })

    this.continue()
  }

  /* istanbul ignore next */
  startParticipantTour() {
    this.addStep({
      title: capFirst(gettext("add participants")),
      text: gettext(
        'For safety reasons, kids courses require you to explicitly set participants. Select an existing profile, or create a new one. You can add as many participants as you like! Click "book" after you have selected all the participants you want to add.',
      ),
      attachTo: {
        element: '[data-tour="add-participants"]',
        on: "bottom",
      },
      canClickTarget: true,
      arrow: false,
      buttons: [this.backInHistoryButton],
    })

    this.continue()
  }
}
